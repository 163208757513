<template>
  <div class="lang-menu">
    <div class="selected-lang">
      <a style="font-family: 'Source Sans Pro', sans-serif;" class="switching" v-on:click="showLocales">▼{{ $t('page.header.language') }}</a>
    </div>
    <ul v-if="show" id="languages">
      <li>
        <a style="font-family: 'Source Sans Pro', sans-serif;" @click="switchLocale('en')" class="us languageChoice">{{ $t('page.header.languages[0]') }}</a>
      </li>
      <li>
        <a style="font-family: 'Source Sans Pro', sans-serif;" @click="switchLocale('cs')" class="cz languageChoice">{{ $t('page.header.languages[1]') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  methods: { // <---------------------------
    switchLocale(locale) {
      this.show=false;
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    showLocales() {
      this.show=!this.show;
    }
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      show: false
    }
  }
}
</script>

<style scoped>
@import '../assets/css/languages.css';
</style>