<template>
  <div class="navbar menu"
       :class="{ 'navbar--hidden': !showNavbar }">
    <div class="child">
      <img v-on:click="goto('home')" alt="logo" class="logo" src="../assets/imagines/logo/2scale.svg">
    </div>
    <nav class="child navMenu">
      <a v-on:click="goto('home')">{{ $t('page.header.modules[0]') }}</a>
      <a v-on:click="goto('services')">{{ $t('page.header.modules[1]') }}</a>
      <a v-on:click="goto('references')">{{ $t('page.header.modules[2]') }}</a>
      <a v-on:click="goto('jobs')">{{ $t('page.header.modules[3]') }}</a>
      <a v-on:click="goto('contact')">{{ $t('page.header.modules[4]') }}</a>
    </nav>
    <div class="child">
      <locale-switcher/>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

const OFFSET = 60;
export default {
  name: "Menu",
  components: {
    LocaleSwitcher
  },
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
    }
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    goto(id) {
      window.history.pushState(id, id, "/" + id);
      if (id === 'home') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        let element = document.getElementById(id);
        //hack -100
        let top = element.offsetTop -100;

        window.scrollTo({
          behavior: 'smooth',
          top: top,
        })
      }
    }
  }
}


</script>

<style scoped>
@import "../assets/css/menu.css";
.navbar {
  z-index: 99 !important;
  height: 100px;
  width: 101vw;
  background: hsl(0, 18%, 99%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(160, 192, 192, 0.5);
  transform: translate3d(0, -40%, 0);
  transition: 0.1s all ease-out;
  top: 38px;
  left: -8px;
}

.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -40%, 0);
}

  .logo {
    height: 40px;
    margin-left: 20%;
    margin-top: 5px;
    pointer-events: visibleFill;
    cursor: pointer;
  }

  .menu {
    display: flex;
    margin-left: 0.5%;
  }

  .menu .child {
    display: flex;
    flex: 1;
    margin-top: 20px;
  }

  .menu .child a {
    flex: 1;
    width: 120px;
    left: 50%;
    margin-top: -30px;
    pointer-events: visibleFill;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
  }
</style>