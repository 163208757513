<template>
  <div id="jobs" class="all">
    <div class="titleJob">
      <h1>
        <br/>
        {{ $t('page.body.jobs.title') }}
      </h1>
    </div>

    <div class="job">
      <br/><br/>
      <h2 style="text-decoration: underline">{{ $t('page.body.jobs.offer.name') }}</h2>
      <br/><br/>
      <h4>{{ $t('page.body.jobs.offer.intro.title') }}</h4>
      <p>{{ $t('page.body.jobs.offer.intro.content[0]') }}<br/><br/>{{ $t('page.body.jobs.offer.intro.content[1]') }}</p>

      <h4>{{ $t('page.body.jobs.offer.yourInterests.title') }}</h4>

      <ul>
        <li>{{ $t('page.body.jobs.offer.yourInterests.content[0]') }}</li>
        <li>{{ $t('page.body.jobs.offer.yourInterests.content[1]') }}</li>
        <li>{{ $t('page.body.jobs.offer.yourInterests.content[2]') }}</li>
        <li>{{ $t('page.body.jobs.offer.yourInterests.content[3]') }}</li>
      </ul>

      <h4>{{ $t('page.body.jobs.offer.skills.title') }}</h4>

      <ul>
        <li>{{ $t('page.body.jobs.offer.skills.content[0]') }}</li>
        <li>{{ $t('page.body.jobs.offer.skills.content[1]') }}</li>
        <li>{{ $t('page.body.jobs.offer.skills.content[2]') }}</li>
        <li>{{ $t('page.body.jobs.offer.skills.content[3]') }}</li>
        <li>{{ $t('page.body.jobs.offer.skills.content[4]') }}</li>
      </ul>

      <br/>
      <h2>{{ $t('page.body.jobs.offer.weOffer.title') }}</h2>
      <br/>

      <ul>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[0]') }}</li>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[1]') }}</li>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[2]') }}</li>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[3]') }}</li>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[4]') }}</li>
        <li>{{ $t('page.body.jobs.offer.weOffer.content[5]') }}</li>
      </ul>
      <h4>{{ $t('page.body.jobs.offer.areYouInterested.title') }}</h4>
      <ul>
        <li>{{ $t('page.body.jobs.offer.areYouInterested.content[0]') }}</li>
        <li>{{ $t('page.body.jobs.offer.areYouInterested.content[1]') }}<strong><a href="mailto:jobs@2scale.cz">{{ $t('page.body.jobs.offer.areYouInterested.content[2]') }}</a></strong></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "Jobs"
}
</script>

<style scoped>
@import "../../assets/css/jobs.css";
</style>