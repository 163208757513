<template>
  <div id="services" class="all">
    <div class="titleServices">
      <h1>
        <br/>{{ $t('page.body.services.title') }}<br/>
      </h1>
      <p><br/>{{ $t('page.body.services.undertitle') }}</p>
    </div>
    <div class="body">
      <div class="intro">
        <h4>{{ $t('page.body.services.content.upper.title') }}</h4>
        <div class="introText">
          <p>{{ $t('page.body.services.content.upper.content') }}</p>
        </div>
      </div>
        <!--HACK <780-->
        <!-- for animation: to transition name-fade and to div v-if="scrollPosition>800 || scrollPosition<780" -->
        <div class="contentT">
          <p>{{ $t('page.body.services.content.text[0]') }}<br/></p>
          <p>{{ $t('page.body.services.content.text[1]') }}<br/></p>
          <p>{{ $t('page.body.services.content.text[2]') }}<br/></p>
          <p>{{ $t('page.body.services.content.text[3]') }}<br/></p>
          <p>{{ $t('page.body.services.content.text[4]') }}<br/></p>
          <p>{{ $t('page.body.services.content.text[5]') }}<br/></p>
        </div>
      <div class="bubble blue">
        <h3>{{ $t('page.body.services.content.bubble[0].title') }}</h3>
        <p>{{ $t('page.body.services.content.bubble[0].text') }}</p>
        <ul>
          <li><p><i>{{ $t('page.body.services.content.bubble[0].point[0].title') }}</i>{{ $t('page.body.services.content.bubble[0].point[0].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[0].point[1].title') }}</i>{{ $t('page.body.services.content.bubble[0].point[1].description') }}</p></li>
        </ul>
      </div>
      <div class="bubble darkgreen">
        <h3>{{ $t('page.body.services.content.bubble[1].title') }}</h3>
        <p>{{ $t('page.body.services.content.bubble[1].text') }}</p>
        <ul>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[0].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[0].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[1].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[1].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[2].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[2].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[3].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[3].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[4].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[4].description') }}</p></li>
          <li><p><i>{{ $t('page.body.services.content.bubble[1].point[5].title') }}</i>{{ $t('page.body.services.content.bubble[1].point[5].description') }}</p></li>
        </ul>
      </div>
      <div class="bubble lightgreen">
        <h3>{{ $t('page.body.services.content.bubble[2].title') }}</h3>
        <p>{{ $t('page.body.services.content.bubble[2].text') }}</p>
        <ul>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[0].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[0].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[1].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[1].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[2].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[2].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[3].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[3].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[4].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[4].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[5].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[5].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[6].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[6].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[7].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[7].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[8].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[8].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[2].point[9].title') }}</i>{{ $t('page.body.services.content.bubble[2].point[9].description') }}</p></li>
        </ul>
      </div>
      <div class="bubble white">
        <h3>{{ $t('page.body.services.content.bubble[3].title') }}</h3>
        <p>{{ $t('page.body.services.content.bubble[3].text') }}</p>
        <ul>
          <li><p> <i>{{ $t('page.body.services.content.bubble[3].point[0].title') }}</i>{{ $t('page.body.services.content.bubble[3].point[0].description') }}</p></li>
          <li><p> <i>{{ $t('page.body.services.content.bubble[3].point[1].title') }}</i>{{ $t('page.body.services.content.bubble[3].point[1].description') }}</p></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data () {
    return {
      scrollPosition: null
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}
</script>

<style scoped>
  @import "../../assets/css/services.css";
</style>