<template>
  <div>
    <Home/>
    <Services/>
    <References/>
    <Jobs/>
    <Contact/>
  </div>
</template>

<script>
import Home from './content/Home.vue'
import Services from "@/components/content/Services";
import References from "@/components/content/References";
import Jobs from "@/components/content/Jobs";
import Contact from "@/components/content/Contact";

export default {
  name: "Content",
  components: {
    Contact,
    Jobs,
    References,
    Services,
    Home
  }
}
</script>

<style scoped>
</style>