<template>
  <div>
  <div class="navbar"
       :class="{ 'navbar--hidden': !showNavbar }">
    <div class="child">
      <img v-on:click="goto('top')" alt="logo" class="logo" src="../assets/imagines/logo/2scale.svg">
    </div>
    <div class="container" v-on:click="myFunction()">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <div class="child">
      <locale-switcher/>
    </div>
    <nav v-if="showMenu" class="navMenu">
      <a class="navMenuItem" v-on:click="goto('top')">{{ $t('page.header.modules[0]') }}</a>
      <a class="navMenuItem" v-on:click="goto('services')">{{ $t('page.header.modules[1]') }}</a>
      <a class="navMenuItem" v-on:click="goto('references')">{{ $t('page.header.modules[2]') }}</a>
      <a class="navMenuItem" v-on:click="goto('jobs')">{{ $t('page.header.modules[3]') }}</a>
      <a class="navMenuItem" v-on:click="goto('contact')">{{ $t('page.header.modules[4]') }}</a>
    </nav>
  </div>

  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

const OFFSET = 60;
export default {
  name: "MenuPhone",
  components: {
    LocaleSwitcher
  },
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      showMenu:false,
    }
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      this.showMenu = false;
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    goto(id) {
      this.showMenu = false;
      window.history.pushState(id, id, "/" + id);
      if (id === 'top') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        let element = document.getElementById(id);
        //hack -100
        let top = element.offsetTop -100;

        window.scrollTo({
          behavior: 'smooth',
          top: top,
        })
      }
    },
    myFunction() {
      this.showMenu = !this.showMenu;
    }
  }
}


</script>

<style scoped>
.navbar {
  z-index: 99 !important;
  height: 100px;
  width: 104vw;
  background: hsl(0, 18%, 99%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(160, 192, 192, 0.5);
  transform: translate3d(0, -40%, 0);
  transition: 0.1s all ease-out;
  top: 38px;
  left: -6px;
}
.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -40%, 0);
}
.logo {
  height: 40px;
  margin-left: 8%;
  margin-top: 25px;
  pointer-events: visibleFill;
  cursor: pointer;
}

.navMenu {
  margin-top: 135px;
  z-index: 2;
  background-color: #dee7de;
  opacity: 0.9;
  height: 170px;
  width: 200px;
  margin-left: 2%;
}
.navMenuItem {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  pointer-events: visibleFill;
  cursor: pointer;
}

.container {
  width: 100px;
  display: inline-block;
  cursor: pointer;
  position: fixed;
  margin-top: -40px;
  margin-left: auto;
  margin-right: auto;
  left: 47%;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #6b6b6b;
  margin: 5px auto;
  transition: 0.4s;
}
@media only screen and (max-width: 850px) {
  .container {
    left: 46%;
  }
}
@media only screen and (max-width: 730px) {
  .container {
    left: 45%;
  }
}
@media only screen and (max-width: 590px) {
  .container {
    left: 44%;
  }
}
@media only screen and (max-width: 515px) {
  .container {
    left: 43%;
  }
}
@media only screen and (max-width: 475px) {
  .container {
    left: 42%;
  }
}
@media only screen and (max-width: 405px) {
  .container {
    left: 41%;
  }
}
@media only screen and (max-width: 395px) {
  .container {
    left: 40%;
  }
}
@media only screen and (max-width: 375px) {
  .logo {
    width: 100px;
    margin-left: 33%;
  }
  .container {
    left: 0%;
    width: 100px;
  }
}
@media only screen and (max-width: 365px) {
  .logo {
    width: 70px;
    margin-left: 38%;
  }
  .container {
    left: -10%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 350px) {
  .logo {
    width: 0px;
  }
  .container {
    margin-left: 20%;
  }
}
</style>