<template>
  <div id="contact" class="all">
    <div class="titleContact">
      <h1>
        <br/>{{ $t('page.body.contact.title') }}
      </h1>
    </div>

    <div class="content">
      <section class="section1">
        <p><img class="image-author" src="../../assets/imagines/people/chris.jpeg"/></p>
        <p>{{ $t('page.body.contact.content.message.text[0]') }}</p>
        <p>
          {{ $t('page.body.contact.content.message.text[1]') }}
          <br/><br/>{{ $t('page.body.contact.content.message.text[2]') }}
          <br/><i>{{ $t('page.body.contact.content.message.text[3]') }}</i>
        </p>
      </section>
      <section class="section2">
        <h3 class="text-uppercase">{{ $t('page.body.contact.content.address.title') }}</h3>
        <p>{{ $t('page.body.contact.content.address.content[0]') }}<br/>
          {{ $t('page.body.contact.content.address.content[1]') }}<br/>
          {{ $t('page.body.contact.content.address.content[2]') }}<br/>
          {{ $t('page.body.contact.content.address.content[3]') }}<br/>
          {{ $t('page.body.contact.content.address.content[4]') }}</p>
        <p>{{ $t('page.body.contact.content.address.content[5]') }}<br/>
        <p><a href="mailto:info@2scale.cz">{{ $t('page.body.contact.content.address.content[6]') }}</a></p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>
@import "../../assets/css/contact.css";
</style>