<template>
  <div id="references" class="all">
    <div class="titleReferences">
      <h1>
        <br/>{{ $t('page.body.references.title') }}<br/>
      </h1>
      <p><br/>{{ $t('page.body.references.undertitle') }}</p>
    </div>
    <div class="body">
      <div class="company">
        <h2>{{ $t('page.body.references.company[0].title') }}</h2>
        <div class="description">
          <img alt="vodafone" src="../../assets/imagines/companies/vodafone.png" width="100" class="image" style="float: left">
          <p style="padding-top: 10px">{{ $t('page.body.references.company[0].text[0]') }}<br/><br/></p>
        </div>
        <ul class="otherPoints" style="margin-left: 130px">
          <li>{{ $t('page.body.references.company[0].points[0]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[1]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[2]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[3]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[4]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[5]') }}</li>
          <li>{{ $t('page.body.references.company[0].points[6]') }}</li>
        </ul>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[1].title') }}</h2>
        <div class="description">
          <img alt="mondia" src="../../assets/imagines/companies/mondia.jpeg" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[1].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[2].title') }}</h2>
        <div class="description">
          <img alt="gfk" src="../../assets/imagines/companies/gfk.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[2].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[3].title') }}</h2>
        <div class="description">
          <img alt="dbsystems" src="../../assets/imagines/companies/dbsystems.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[3].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[4].title') }}</h2>
        <div class="description">
          <img alt="cognis" src="../../assets/imagines/companies/cognis.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[4].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[5].title') }}</h2>
        <div class="description">
          <img alt="santander" src="../../assets/imagines/companies/santander.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[5].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[6].title') }}</h2>
        <div class="description">
          <img alt="tipp24" src="../../assets/imagines/companies/tipp24.png" width="100" style="float: left" class="image">
          <p>
            {{ $t('page.body.references.company[6].text[0]') }}
            <a class="greenHref" href="https://www.haproxy.org" target="_blank">{{ $t('page.body.references.company[6].text[1]') }}</a>
            {{ $t('page.body.references.company[6].text[2]') }}
            <a class="greenHref" href="https://www.linuxvirtualserver.org" target="_blank">{{ $t('page.body.references.company[6].text[3]') }}</a>
            {{ $t('page.body.references.company[6].text[4]') }}
            <a class="greenHref" href="https://www.spread.org" target="_blank">{{ $t('page.body.references.company[6].text[5]') }}</a>
            {{ $t('page.body.references.company[6].text[6]') }}<br/><br/>
          </p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[7].title') }}</h2>
        <div class="description">
          <img alt="sport1" src="../../assets/imagines/companies/sport1.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[7].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[8].title') }}</h2>
        <div class="description">
          <img alt="alice" src="../../assets/imagines/companies/alice.png" width="100" style="float: left" class="image">
          <p>
            {{ $t('page.body.references.company[8].text[0]') }}<br/>
            {{ $t('page.body.references.company[8].text[1]') }}<br/><br/>
          </p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[9].title') }}</h2>
        <div class="description">
          <p>
            {{ $t('page.body.references.company[9].text[0]') }}<br/><br/>
          </p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[10].title') }}</h2>
        <div class="description">
          <img alt="ihg" src="../../assets/imagines/companies/ihg.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[10].text[0]') }}<br/><br/></p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[11].title') }}</h2>
        <div class="description">
          <p>
            {{ $t('page.body.references.company[11].text[0]') }}
            <a class="greenHref" href="https://www.haproxy.org" target="_blank">{{ $t('page.body.references.company[11].text[1]') }}</a>
            {{ $t('page.body.references.company[11].text[2]') }}
            <a class="greenHref" href="https://www.linuxvirtualserver.org" target="_blank">{{ $t('page.body.references.company[11].text[3]') }}</a>
            {{ $t('page.body.references.company[11].text[4]') }}
            <a class="greenHref" href="https://www.spread.org" target="_blank">{{ $t('page.body.references.company[11].text[5]') }}</a>
            {{ $t('page.body.references.company[11].text[6]') }}<br/><br/>
          </p>
        </div>
      </div>
      <div class="company">
        <h2>{{ $t('page.body.references.company[12].title') }}</h2>
        <div class="description">
          <img alt="optus" src="../../assets/imagines/companies/optus.png" width="100" style="float: left" class="image">
          <p>{{ $t('page.body.references.company[12].text[0]') }}<br/><br/></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "References"
}
</script>

<style scoped>
@import "../../assets/css/references.css";
</style>