<template>
  <div class="footer">
      <div class="container">
        <div class="text aboutUs">
          <h3>{{ $t('page.footer.modules.aboutUs.title') }}</h3>
          <ul>
            <p>{{ $t('page.footer.modules.aboutUs.content[0]') }}
              <a href="https://2scale.net" target="_blank">{{ $t('page.footer.modules.aboutUs.content[1]') }}</a>{{ $t('page.footer.modules.aboutUs.content[2]') }}</p>
            <p>{{ $t('page.footer.modules.aboutUs.content[3]') }}
              <strong>{{ $t('page.footer.modules.aboutUs.content[4]') }}</strong>
              {{ $t('page.footer.modules.aboutUs.content[5]') }}</p>
            <p>{{ $t('page.footer.modules.aboutUs.content[6]') }}</p>
          </ul>
        </div>
        <div class="text contact">
          <h3>{{ $t('page.footer.modules.contact.title') }}</h3>
          <ul>
            <p>{{ $t('page.footer.modules.contact.content[0]') }}<br/>
              {{ $t('page.footer.modules.contact.content[1]') }}<br/>
              {{ $t('page.footer.modules.contact.content[2]') }}<br/>
              {{ $t('page.footer.modules.contact.content[3]') }}<br/>
              {{ $t('page.footer.modules.contact.content[4]') }}</p>
            <p>{{ $t('page.footer.modules.contact.content[5]') }}<br/>
              {{ $t('page.footer.modules.contact.content[6]') }}</p>
            <div class="social">
              <a href="https://www.facebook.com/2scalesro" target="_blank">Facebook </a>
              <a href="https://www.linkedin.com/company/2scale-cz/?originalSubdomain=cz" target="_blank">LinkedIn</a>
            </div>
          </ul>
        </div>
        <div class="text imprint">
          <h3>{{ $t('page.footer.modules.imprint.title') }}</h3>
          <ul>
            <p>{{ $t('page.footer.modules.imprint.content[0]') }}<br/>
              {{ $t('page.footer.modules.imprint.content[1]') }}</p>
            <p>{{ $t('page.footer.modules.imprint.content[2]') }}<br/>
              {{ $t('page.footer.modules.imprint.content[3]') }}</p>
            <p>{{ $t('page.footer.modules.imprint.content[4]') }}<br/>
              {{ $t('page.footer.modules.imprint.content[5]') }}</p>
            <p>{{ $t('page.footer.modules.imprint.content[6]') }}<br/>
              {{ $t('page.footer.modules.imprint.content[7]') }}</p>
          </ul>
        </div>
    </div>
    <div class="copyrightbody">
      <div class="copyright"><br/><br/><p>{{ $t('page.footer.copyright') }}</p><br/><br/></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
@import '../assets/css/footer.css';
</style>