<template>
  <div class="all">
    <div class="titleHome">
      <div class="item">
        <div v-if="showFirst" class="homeText">
          <h1>{{ $t('page.body.home.tops[0].title') }}</h1>
          <p><br/>{{ $t('page.body.home.tops[0].text') }}</p>
        </div>
        <div v-if="showSecond" class="homeText">
          <h1>{{ $t('page.body.home.tops[1].title') }}</h1>
          <p><br/>{{ $t('page.body.home.tops[1].text') }}</p>
        </div>
        <div v-if="showThird" class="homeText">
          <h1>{{ $t('page.body.home.tops[2].title') }}</h1>
          <p><br/>{{ $t('page.body.home.tops[2].text') }}</p>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="point pointLeft">
        <div class="pic">
          <img src="../../assets/imagines/home/agile.png">
        </div>
        <div class="text">
          <h2>{{ $t('page.body.home.bots[0].title') }}</h2>
          <p><br/>{{ $t('page.body.home.bots[0].text') }}</p>
        </div>
      </div>
      <div class="point pointRight">
        <div class="pic">
          <img src="../../assets/imagines/home/consulting.png">
        </div>
        <div class="text">
          <h2>{{ $t('page.body.home.bots[1].title') }}</h2>
          <p><br/>{{ $t('page.body.home.bots[1].text') }}</p>
        </div>
      </div>
      <div class="point pointLeft">
        <div class="pic">
          <img src="../../assets/imagines/home/cloud.png">
        </div>
        <div class="text">
          <h2>{{ $t('page.body.home.bots[2].title') }}</h2>
          <p><br/>{{ $t('page.body.home.bots[2].text') }}</p>
        </div>
      </div>
      <div class="point pointRight">
        <div class="pic">
          <img src="../../assets/imagines/home/devops.png">
        </div>
        <div class="text">
          <h2>{{ $t('page.body.home.bots[3].title') }}</h2>
          <p><br/>{{ $t('page.body.home.bots[3].text') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      showFirst: false,
      showSecond: false,
      showThird: false
    }
  },
  methods: {
    showHide: function() {
      if (this.showFirst) {
        this.showFirst=false;
        this.showSecond=true;
      } else if (this.showSecond) {
        this.showSecond=false;
        this.showThird=true;
      } else if (this.showThird) {
        this.showThird=false;
        this.showFirst=true;
      } else {
        this.showFirst=true;
      }
    }
  },
  mounted: function() {
    this.showHide();
    setInterval(this.showHide, 5000);
  }
}

</script>

<style scoped>
@import "../../assets/css/home.css";
</style>