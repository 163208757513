<template>
  <div class="website">
    <div v-if="!isMobile()">
      <Menu/>
    </div>
    <div v-else>
      <MenuPhone/>
    </div>
    <Content/>
    <Footer/>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import MenuPhone from './components/MenuPhone.vue'
import Content from './components/Content.vue'
import Footer from "@/components/Footer"

export default {
  name: 'App',
  components: {
    Footer,
    Menu,
    MenuPhone,
    Content
  },
  data() {
    return {
      windowWidth: 0
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  metaInfo () {
    return {
      title: '2scale Czech Republic s.r.o.',
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(
          resolve => setTimeout(resolve, ms)
      );
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isMobile() {
      return (this.windowWidth < 1044);
    },
    async goToUrl(id) {
      console.log(id);
      await this.sleep(500);
      if (id === 'home') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        let element = document.getElementById(id);
        //hack -100
        let top = element.offsetTop - 100;

        window.scrollTo({
          behavior: 'smooth',
          top: top,
        })
      }
    }
  },
  mounted() {
    console.log("page loaded");
    this.goToUrl(window.location.href.split('/')[3]);
  }
}
</script>

<style>
  .website {
    margin-left: auto;
    margin-right: auto;
  }
  p, h1, h2, h3, h4, h5, h6, i, li, ul, th, a, div {
    font-family: "Source Sans Pro", sans-serif;
  }
</style>
